import { Box, Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import { decimalRoundOff, moneyFormat, validateNull } from "utils";

const ViewOnly = ({ label, value, xs = 4 }) => {
  const theme = useTheme();
  return (
    <Grid item xs={xs}>
      <Typography
        sx={{
          fontSize: 12,
          color: theme.palette.grey["A300"],
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ fontSize: 14, color: theme.palette.grey["A300"] }}>
        {value}
      </Typography>
    </Grid>
  );
};

const TooltipView = ({ data = {} }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 400,
        padding: 2,
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Grid container spacing={1}>
        <ViewOnly label="SO line" value={validateNull(data?.soLine)} />
        <ViewOnly
          label="Pending released quantity"
          value={validateNull(data?.pendingReleaseQty)}
          xs={5.5}
        />
        <ViewOnly
          label="Invoiced price"
          value={moneyFormat(decimalRoundOff(data?.invoicedPrice))}
          xs={2.5}
        />
      </Grid>
    </Box>
  );
};

TooltipView.propTypes = {
  data: PropTypes.object,
};

export default TooltipView;
