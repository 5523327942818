import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { OTHER_CHARGES_HEADERS } from "pages/SupplyPanel/constant";
import PropTypes from "prop-types";

import { moneyFormat, pascalCase } from "utils";

const OtherChargesBreakUp = ({ processingCharges }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {OTHER_CHARGES_HEADERS.map((header) => (
              <TableCell key={header}>
                <Typography fontSize={14} fontWeight="bold">
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!processingCharges?.length &&
            processingCharges
              .filter((charge) => charge?.amount > 0)
              .map((row, index) => {
                const amountWithTax =
                  row?.amount + row?.amount * row?.taxPercentage * 0.01;
                const baseAmount = row?.amount;
                const gstTax = row?.amount * row?.taxPercentage * 0.01;

                return (
                  <TableRow key={index}>
                    <TableCell>{pascalCase(row?.description)}</TableCell>
                    <TableCell>{moneyFormat(baseAmount)}</TableCell>
                    <TableCell>{moneyFormat(gstTax)}</TableCell>
                    <TableCell>{moneyFormat(amountWithTax)}</TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OtherChargesBreakUp;

OtherChargesBreakUp.propTypes = {
  processingCharges: PropTypes.array,
};
