import { createTheme, tableCellClasses } from "@mui/material";

const theme = createTheme();

const primaryBlue = "#314d85";
const secondaryBlue = "#738ec6";
const error = "#fff4f4";
const errorDark = "#ff5a5f";
const success = "#f3fff3";
const successDark = "#02b474";
const warning = "#fefcf6";
const black = "#000";
const white = "#fff";
const blackDark = "#212121";
const regularDark = "#262626";
const footerNavBackTop = "#373737";
const footerNavBackBottom = "#d1cdcd";
const bodyTextColor = "#101010";
const primaryGray = "#999999";
const secondaryGray = "#888888";
const selectionBoxStroke = "#afc4f3";
const primaryDisabledText = "#bbb";
const primaryDisabled = "#e6e6e6";
const footerNavText = "#e7c2a1";
const textBoxStroke = "#e9edf0";
const selectionBoxFill = "#f2f6ff";
const grayLight = "#f3f3f3";
const priceBack = "#f5f5f5";
const grayLightest = "#fafafa";
const textBoxFill = "#fbfcfc";
const navigationBgColor = "#F8FAFB";
const avatarBlue = "#e6f6ff";
const avatarPink = "#ffe6e6";
const avatarYellow = "#fff5e6";
const lightGreen = "#eafae4";
const darkGreen = "#6bb850";
const borderGrey = "#e9e9e9";
const lightYellow = "#fffef5";
const darkYellow = "#eabb42";
const tableHead = "#e9f0ff";
const hoverTable = "#f8fafb";
const shadowGrey = "#00000014";
const lightestBlue = "#edf6ff";
const lightBlue = "#97cdff";
const lightBlueBackround = "#f6f8ff";
const grayText = "#70737a";
const grayBgColor = "#f1f1f1";
const blueNavyColor = "#1e3565";
const blueBorderColor = "#5d74a6";
const dimBlackColor = "#212529";
const blueText = "#0000EE";
const red = "#ff0000";
const redPrimary = "#d32f2f";
const errorBgColor = "#fff3f3";
const lightError = "#d32f2f";
const errorTextColor = "#CB4154";
const errorLightBgColor = "#F9E5E5";

const shadows = theme.shadows;
shadows[0] = `10px 0px 9px ${shadowGrey}`;

const typographyOptions = (theme) => {
  return {
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    h1: {
      fontSize: "2rem",
      margin: "0 0 1.25rem 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        lineHeight: 1.333,
        letterSpacing: "0.0125rem",
        fontWeight: 600,
        margin: "0 0 1rem 0",
      },
    },
    h2: {
      fontSize: "1.75rem",
      margin: "0 0 1rem 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
        lineHeight: 1.4,
        fontWeight: 600,
      },
    },
    h3: {
      fontSize: "1.5rem",
      margin: "0 0 1rem 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
        lineHeight: 1.444,
        fontWeight: 600,
      },
    },
    h4: {
      fontSize: "1.25rem",
      margin: "0 0 0.5rem 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: 600,
      },
    },
    h5: {
      fontSize: "1.125rem",
      margin: "0 0 0.5rem 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: 600,
      },
    },
    body1: {
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    body2: {
      fontSize: "0.875rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    button: {
      texttransform: "none",
    },
  };
};

export const withTheme = (theme) => {
  theme.typography = {
    ...theme.typography,
    ...typographyOptions(theme),
  };
  return theme;
};

const lightThemeOptions = {
  palette: {
    mode: "light",
    common: {
      black: `${black}`,
      white: `${white}`,
      red,
    },
    primary: {
      main: `${primaryBlue}`,
      light: `${secondaryBlue}`,
      blueText: `${blueText}`,
    },
    secondary: {
      main: `${primaryBlue}`,
      light: `${secondaryBlue}`,
    },
    success: {
      main: `${success}`,
      dark: `${successDark}`,
    },
    info: {
      main: `${primaryBlue}`,
      light: `${lightBlue}`,
      lightest: `${lightestBlue}`,
      lightBlueBackround: `${lightBlueBackround}`,
      blueNavyColor: `${blueNavyColor}`,
      blueBorderColor: `${blueBorderColor}`,
    },
    warning: {
      main: `${warning}`,
      light: `${lightYellow}`,
      dark: `${darkYellow}`,
    },
    error: {
      main: `${error}`,
      dark: `${errorDark}`,
      light: lightError,
      errorBgColor,
      errorTextColor,
      errorLightBgColor,
    },
    buttonError: {
      main: `${redPrimary}`,
    },
    grey: {
      50: `${textBoxFill}`,
      100: `${grayLightest}`,
      200: `${grayLight}`,
      300: `${textBoxStroke}`,
      400: `${primaryDisabled}`,
      500: `${footerNavText}`,
      600: `${selectionBoxStroke}`,
      700: `${secondaryGray}`,
      800: `${footerNavBackTop}`,
      900: `${footerNavBackBottom}`,
      1000: `${grayText}`,
      A100: `${selectionBoxFill}`,
      A200: `${blackDark}`,
      A300: `${regularDark}`,
      A400: `${priceBack}`,
      A700: `${borderGrey}`,
      A800: `${dimBlackColor}`,
      A900: `${grayBgColor}`,
    },
    text: {
      primary: `${bodyTextColor}`,
      secondary: `${primaryGray}`,
      disabled: `${primaryDisabledText}`,
    },
    divider: `${textBoxStroke}`,
    bgColor: `${navigationBgColor}`,
    avatar: {
      yellow: `${avatarYellow}`,
      pink: `${avatarPink}`,
      blue: `${avatarBlue}`,
    },
    green: {
      dark: `${darkGreen}`,
      light: `${lightGreen}`,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows,
  spacing: (factor) => `${0.25 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
          fontSize: "1rem",
          fontWeight: "normal",
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          boxSizing: "border-box",
          color: `${bodyTextColor}`,
          letterSpacing: "0.2px",
        },
        link: {
          textDecoration: "none",
        },
        a: {
          textDecoration: "none",

          "&.secondary": {
            color: `${primaryBlue}`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        texttransform: "none",
      },
      styleOverrides: {
        root: {
          texttransform: "none",
          fontSize: 16,
          fontWeight: 600,
          boxSizing: "border-box",
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        },
        sizeMedium: {
          paddingInline: 32,
          fontSize: 16,
          lineHeight: 1.75,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root:hover fieldset": {
            borderColor: "#0000003b",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
            fontSize: "1rem",
          },
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          [`&.${tableCellClasses.head}`]: {
            backgroundColor: tableHead,
            color: bodyTextColor,
            fontWeight: "600",
            cursor: "default",
            ":first-of-type": {
              borderTopLeftRadius: 8,
            },
            ":last-child": {
              borderTopRightRadius: 8,
            },
          },
          [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even):not(.row-no-bg)": {
            backgroundColor: hoverTable,
          },
          // hide last border
          "&:last-child td, &:last-child th": {
            border: 0,
          },
          "&:hover": {
            backgroundColor: selectionBoxFill,
            cursor: "pointer",
          },
        },
      },
    },
  },
};

export default withTheme(createTheme(lightThemeOptions));
