import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import theme from "themeProvider";

const CollapsibleInfo = forwardRef(
  (
    {
      summary,
      details,
      defaultExpand = false,
      detailsStyle,
      summaryStyles,
      hideExpandIcon = false,
      isExpandOnIconClickOnly = false,
      headerStyle,
      onExpandCollapse,
      style,
    },
    ref,
  ) => {
    const [isExpanded, setExpanded] = useState(false);

    /**
     * expose expand collapsible reference
     */
    useImperativeHandle(ref, () => ({
      handleExpand: (isExpand) => {
        setExpanded(isExpand);
      },
    }));

    const onExpandIconClick = () => {
      onExpandCollapse && onExpandCollapse(isExpanded);
      setExpanded(!isExpanded);
    };

    useEffect(() => {
      defaultExpand && setExpanded(true);
      hideExpandIcon && setExpanded(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Accordion
        expanded={isExpanded}
        style={{
          border: `1px solid ${theme.palette.grey["A100"]}`,
          ...style,
        }}
        defaultExpanded={defaultExpand}
      >
        <AccordionSummary
          onClick={() =>
            !isExpandOnIconClickOnly && !hideExpandIcon && onExpandIconClick()
          }
          style={{
            backgroundColor: theme.palette.grey["A100"],
            ...headerStyle,
          }}
        >
          {
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                  userSelect: "text",
                  ...summaryStyles,
                }}
              >
                {summary}
              </Box>

              {!hideExpandIcon && (
                <IconButton
                  onClick={() => isExpandOnIconClickOnly && onExpandIconClick()}
                  aria-label="expand"
                  disableRipple={true}
                  sx={{
                    "&.MuiIconButton-root": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  {isExpanded ? (
                    <ArrowDropUp fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
              )}
            </Box>
          }
        </AccordionSummary>
        {isExpanded && (
          <AccordionDetails style={detailsStyle}>{details}</AccordionDetails>
        )}
      </Accordion>
    );
  },
);

export default CollapsibleInfo;

CollapsibleInfo.prototype = {
  summary: PropTypes.element.isRequired,
  details: PropTypes.element,
  detailsStyle: PropTypes.object,
  summaryStyles: PropTypes.object,
  hideExpandIcon: PropTypes.bool,
  defaultExpand: PropTypes.bool,
  isExpandOnIconClickOnly: PropTypes.bool,
  headerStyle: PropTypes.object,
  onExpandCollapse: PropTypes.func,
  style: PropTypes.object,
};
