import React from "react";
import { Chip, useTheme } from "@mui/material";

import { INVOICE_POSTING_LABEL, SHIPMENT_STATUS_CHIP } from "./constants";

const StatusChip = ({ label, status }) => {
  const theme = useTheme();

  const getCustomStyle = () => {
    let style = {};
    switch (status) {
      case SHIPMENT_STATUS_CHIP.ON_TIME:
      case INVOICE_POSTING_LABEL.success:
        style = {
          borderColor: theme.palette.success.dark,
          color: theme.palette.success.dark,
          background: theme.palette.success.main,
        };
        break;
      case SHIPMENT_STATUS_CHIP.DELAYED:
      case INVOICE_POSTING_LABEL.failed:
        style = {
          borderColor: theme.palette.error.dark,
          color: theme.palette.error.dark,
          background: theme.palette.error.errorLightBgColor,
        };
        break;
      case INVOICE_POSTING_LABEL.pending:
        style = {
          borderColor: theme.palette.warning.dark,
          color: theme.palette.warning.dark,
          background: theme.palette.warning.light,
        };
        break;
      case SHIPMENT_STATUS_CHIP.CANCELLED:
        style = {
          borderColor: theme.palette.text.secondary,
          color: theme.palette.text.secondary,
          background: theme.palette.grey["A900"],
        };
        break;
      default:
        break;
    }
    return style;
  };

  return (
    <Chip
      label={label}
      sx={{
        ...getCustomStyle(),
        borderRadius: 1,
        border: 1,
        borderStyle: "solid",
        fontWeight: 600,
      }}
    />
  );
};

export default React.memo(StatusChip);
