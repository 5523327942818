import { Box, TextField, Grid, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { ButtonV1, DropdownV1, MultiSelectDropdown } from "components";

import { getCall_v2 } from "services";
import { useToaster } from "hooks";

import { error, BUSINESS_TYPES } from "constants";

import { SHIPMENT_STATUS } from "../../constant";

const CustomTextField = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          label={label}
          onChange={onChange}
          value={value}
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

const Filters = ({ onFilterSubmit, onResetFilter }) => {
  const [sellerStates, setSellerStates] = useState([]);
  const [isDirtySellerStates, setIsDirtySellerStates] = useState(false);
  const [isDirtyBusinessUnit, setIsDirtyBusinessUnit] = useState(false);
  const businessUnitRef = useRef(null);
  const deliveryStateRef = useRef(null);
  const triggerToaster = useToaster();

  const theme = useTheme();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      invoiceNumber: "",
      customerGSTIN: "",
      sellerOrderNumber: "",
      orderNumber: "",
      orderCategory: "",
      status: "",
    },
  });

  const handleFilter = (data) => {
    const deliveryStates = deliveryStateRef?.current?.fetchValue();

    const formattedDeliveryStates = sellerStates
      .filter((sellerState) => deliveryStates.includes(sellerState.stateLabel))
      .map((sellerState) => sellerState.stateValue);

    const filerData = {
      ...data,
      businessUnit: businessUnitRef.current?.fetchValue(),
      deliveryStates: formattedDeliveryStates,
    };

    onFilterSubmit(filerData); // Pass form values to the parent component
  };

  const handleReset = () => {
    reset(); // Reset the form to default values
    onFilterSubmit({});
    deliveryStateRef?.current?.resetValue();
    businessUnitRef?.current?.resetValue();
    onResetFilter();
  };

  useEffect(() => {
    fetchSellerStates();
  }, []);

  const fetchSellerStates = async () => {
    try {
      const res = await getCall_v2(`/joms/api/external/v1/stateNames`);
      if (res?.data?.isSuccess) {
        setSellerStates(res?.data?.stateMasterDTOList);
      }
    } catch (err) {
      triggerToaster(
        err?.data?.message || "Error while fetching seller states.",
        error,
      );
    }
  };

  const getIsDirty = () => {
    return isDirty || isDirtyBusinessUnit || isDirtySellerStates;
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderRadius: 2,
        padding: 3,
      }}
    >
      <Box p={2} as="form" onSubmit={handleSubmit(handleFilter)}>
        <Grid container spacing={2} columns={10}>
          <Grid item xs={10} md={2}>
            <CustomTextField
              name={"invoiceNumber"}
              label="Invoice number"
              control={control}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <CustomTextField
              name={"customerGSTIN"}
              label="Customer GSTIN"
              control={control}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <CustomTextField
              name={"orderNumber"}
              label="Order number"
              control={control}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <CustomTextField
              name={"sellerOrderNumber"}
              label="Seller order number"
              control={control}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <Controller
              control={control}
              name={"orderCategory"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  itemList={["DISTRIBUTED", "PRIVATE_BRAND"]}
                  value={value}
                  onChange={onChange}
                  listType="array"
                  placeholder={"Order category"}
                />
              )}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <Controller
              control={control}
              name={"status"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  itemList={Object.values(SHIPMENT_STATUS)}
                  value={value}
                  onChange={onChange}
                  listType="array"
                  placeholder={"Status"}
                />
              )}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <MultiSelectDropdown
              ref={businessUnitRef}
              itemList={BUSINESS_TYPES}
              displayAttribute={"state"}
              label={"Business unit"}
              isListOfObjects={true}
              defaultSelectedValues={[]}
              setIsDirty={setIsDirtyBusinessUnit}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <MultiSelectDropdown
              ref={deliveryStateRef}
              itemList={sellerStates}
              displayAttribute={"stateLabel"}
              label="Delivery state"
              isListOfObjects={true}
              defaultSelectedValues={[]}
              setIsDirty={setIsDirtySellerStates}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <ButtonV1
              title={"Submit"}
              type="submit"
              style={{ width: "100%" }}
              disabled={!getIsDirty()}
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <ButtonV1
              title={"Reset"}
              variant="outlined"
              disabled={!getIsDirty()}
              style={{ width: "100%" }}
              onClick={handleReset}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filters;

Filters.propTypes = {
  onFilterSubmit: PropTypes.func,
  onResetFilter: PropTypes.func,
  totalRecords: PropTypes.number,
};
