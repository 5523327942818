import { Grid, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { downloadPdf, getFileExtension, getSubstring } from "utils";

const GridView = ({ label, fileUrl, fileName }) => {
  const handleDownloadFile = () => {
    if (fileUrl) {
      downloadPdf(fileUrl, fileName);
    }
  };

  return (
    <Grid item xs={2}>
      <Box p={2}>
        <Typography style={{ fontSize: 14, lineHeight: 1.2 }}>
          {`${label}`}
        </Typography>
        <Typography
          onClick={handleDownloadFile}
          color="primary"
          style={{
            fontWeight: "bold",
            fontSize: 14,
            textDecoration: fileUrl?.length > 1 && "underline",
            cursor: "pointer",
          }}
        >
          {fileName ?? "-"}
        </Typography>
      </Box>
    </Grid>
  );
};

const TooltipAttachmentView = ({ shipmentDetails }) => {
  const invoiceFile = shipmentDetails?.invoiceUrl
    ? `invoice_${shipmentDetails?.invoiceNumber}.` +
      getFileExtension(shipmentDetails?.invoiceUrl)
    : "-";

  return (
    <Grid container spacing={2} columns={10}>
      <GridView
        label="Invoice"
        fileUrl={shipmentDetails?.invoiceUrl}
        fileName={invoiceFile}
      />
      <GridView
        label="E-way bill"
        fileUrl={shipmentDetails?.testEWayBill}
        fileName={getSubstring(shipmentDetails?.testEWayBill, 20)}
      />
      <GridView
        label="Test certificate"
        fileUrl={shipmentDetails?.testCertificate}
        fileName={getSubstring(shipmentDetails?.testCertificate, 20)}
      />
      <GridView
        label="Lorry receipts"
        fileUrl={shipmentDetails?.lorryReceipt}
        fileName={getSubstring(shipmentDetails?.lorryReceipt, 20)}
      />
      <GridView
        label="Other"
        fileUrl={shipmentDetails?.other}
        fileName={getSubstring(shipmentDetails?.other, 20)}
      />
    </Grid>
  );
};

TooltipAttachmentView.propTypes = {
  shipmentDetails: PropTypes.object,
};

export default TooltipAttachmentView;
