import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  TextareaAutosize,
  // Stack,
  // Checkbox,
  FormControlLabel,
  Divider,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import {
  CollapsibleInfo,
  DropdownV1,
  ButtonV1,
  ModalBox,
  Loader,
  FloatingBar,
  TextField,
  DatePickerV1,
  DialogBox,
} from "components";
import {
  ShipmentHeader,
  GridSkeleton,
  ShipmentDeliveryData,
  CustomViewOnly,
  CustomTextField,
  ErrorMsg,
} from "./ShipmentCustomComponents";
import ShipmentLineItem from "./ShipmentLineItem";
// import OtherServiceCharges from "./OtherServiceCharges";
import ZohoOtherServiceCharges from "./ZohoOtherServiceCharges";
import FileUpload from "./FileUpload";
import TrackingShipment from "./TrackingShipment";
import PayoutCreationModal from "../DetailsTab/PayoutCreationModal";

import { getOtherServicesModel, makeShipmentUpdationData } from "./DataModel";
import {
  // getTotalInvoiceAmt_otherServices,
  getTotalInvoiceAmt_baseValue,
  getTotalInvoiceAmount_withGST,
  registerServiceChargeValues,
  moneyString,
  checkInvoiceValues,
  getZohoTotalInvoiceAmt_otherServices,
} from "./shipmentCalculationUtil";
import {
  hookFormLabel,
  INVOICED_VALIDATION_MSG,
  INVOICED_FILE_VALIDATION_MSG,
  success,
  error,
  shipmentStatus,
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_CHIP,
  fullHold,
  partialHold,
  release,
  // SHORT_CLOSE_MSG,
  // orderStatus,
} from "./constants";
import { ShipmentUpdateSchema } from "./TestSchema";
import theme from "themeProvider";
import {
  moneyFormat,
  getStartDate,
  dateConvertor,
  decimalRoundOff,
} from "utils";
import { getCall_v2, postCall_v2, putCall_v2 } from "services/updateCall";
import { getShipFromAddress } from "../makeData";
import { getZohoOtherServicesModel } from "./DataModel/otherServicesModel";
import { getInvoicePostingStatus } from "./DataModel/shipmentUpdate";
import { useToaster, useCategoryTypeCheck } from "hooks";
import { updateOrderDetailsAction } from "dataStore/orderDetails";

const HoldPayoutTextField = ({ control, holdType }) => {
  return (
    <Controller
      control={control}
      name="payoutHoldAmount"
      render={({ field: { value, onChange } }) => {
        return (
          <TextField
            customPadding={10}
            value={value}
            onChange={onChange}
            type="number"
            disabled={holdType === fullHold}
            placeholder="Enter the hold amount"
          />
        );
      }}
    />
  );
};

const ShipmentUpdate = ({
  shipmentInfo,
  id,
  getShipmentData,
  lineItems,
  zohoProcessingCharges,
}) => {
  const triggerToaster = useToaster();
  const dispatch = useDispatch();
  const { isDirect, isDistributed, isPrivateBrand } = useCategoryTypeCheck();

  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [isOtherServicesModalOpen, setOtherServicesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditPeriod, setCreditPeriod] = useState(0);
  // const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // const [isPayoutModal, setIsPayoutModal] = useState(false);
  // const [isShortCloseLoader, setIsShortCloseLoader] = useState(false);

  const shipmentList = useSelector((state) => state.shipmentDetails);
  const orderDetails = useSelector((state) => state.orderDetails);

  const fileUploadRef = useRef(null);

  const isCreditOrder = useMemo(
    () => orderDetails.orderPaymentType === "Credit",
    [orderDetails],
  );

  // const orderDate = useMemo(
  //   () => getStartDate(orderDetails.orderDate),
  //   [orderDetails],
  // );

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(
      ShipmentUpdateSchema(orderDetails, shipmentDetails?.lineItemsDetails),
    ),
    defaultValues: {
      lineItemsDetails: [],
      zohoServiceCharges: [],
    },
  });
  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    // clearErrors,
    formState: { errors, isDirty },
    watch,
    resetField,
  } = methods;

  const holdType = watch("holdType");
  const invoiceDueDate = watch("invoiceDueDate");
  const otherChargeRef = useRef(null);

  /* const isValidShortClose = (shipmentRes) => {
    const { currentState } = shipmentRes;
    return (
      ((isDistributed || isPrivateBrand) &&
        currentState.state === SHIPMENT_STATUS.INVOICED) ||
      (isDirect && currentState.state === SHIPMENT_STATUS.DISPATCHED)
    );
  };

  const checkShortClose = (dispatchedCrossedThreshold) => {
    if (dispatchedCrossedThreshold) {
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirm = async () => {
    setIsPayoutModal(true);
  };

  const handleShortClose = async (isPayout) => {
    try {
      setIsShortCloseLoader(true);
      const res = await postCall_v2(
        `oms/order/short-close`,
        {},
        {
          customerOrdNum: orderDetails?.orderNumber,
          isPayoutApplicable: isPayout,
          isManualShortClose: true,
        },
      );
      if (res) {
        dispatch(updateOrderDetailsAction(orderDetails?.orderNumber));
        triggerToaster(`SKU's short closed`, success);
      }
    } catch (err) {
      triggerToaster("Something went wrong. Try after sometime", error);
      console.log("error", err);
    } finally {
      setIsConfirmModalOpen(false);
      setIsPayoutModal(false);
      setIsShortCloseLoader(false);
    }
  };
*/
  const onSubmitShipment = async () => {
    setLoading(true);
    const result = makeShipmentUpdationData(
      getValues(),
      shipmentDetails,
      shipmentList,
      isCreditOrder,
      isDirect,
    );

    const {
      currentState: { state },
    } = shipmentDetails;
    if (getValues("shipmentStatus") === SHIPMENT_STATUS?.READY_TO_SHIP) {
      const checkInvoiceData = checkInvoiceValues(getValues());
      if (!checkInvoiceData) {
        triggerToaster(INVOICED_VALIDATION_MSG, error);
        setLoading(false);
        return;
      }
      const files =
        fileUploadRef?.current?.getUploadedInvoices() &&
        Object.keys(fileUploadRef?.current?.getUploadedInvoices());
      if (!files?.length) {
        triggerToaster(INVOICED_FILE_VALIDATION_MSG, error);
        setLoading(false);
        return;
      }
    }
    await updateShipment(result, shipmentDetails?.number);
  };

  const updateShipment = async (result, shipmentNumber) => {
    try {
      const data = await putCall_v2("/oms/shipment", result);
      if (data) {
        getShipmentData();
        triggerToaster(`Shipment updated successfully`, success);
      }
    } catch (err) {
      if (err && err?.data && err?.data?.detail) {
        triggerToaster(err?.data?.detail, error);
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleOtherServicesModal = () =>
    setOtherServicesModal(!isOtherServicesModalOpen);

  const resetUnconfirmedCharges = () => {
    otherChargeRef.current.resetValues();
    toggleOtherServicesModal();
    retriggerCalculation();
  };

  const calculateInvoiceAmt = useCallback(() => {
    const {
      // taxprocessingCharge,
      // taxloadingCharge,
      // taxfreightCharge,
      // taxotherCharge,
      totalInvoiceOtherServicesAmt,
    } = hookFormLabel;
    let invoiceAmtOtherServices = 0;
    // if (isZohoCustomer) {
    invoiceAmtOtherServices = getZohoTotalInvoiceAmt_otherServices(
      getValues("zohoServiceCharges"),
    );
    // } else {
    //   invoiceAmtOtherServices = getTotalInvoiceAmt_otherServices(
    //     getValues(taxfreightCharge),
    //     getValues(taxprocessingCharge),
    //     getValues(taxloadingCharge),
    //     getValues(taxotherCharge),
    //   );
    // }

    setValue(
      totalInvoiceOtherServicesAmt,
      decimalRoundOff(invoiceAmtOtherServices),
    );

    retriggerCalculation();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    getValues(hookFormLabel.confirmprocessingCharge),
    getValues(hookFormLabel.gstprocessingCharge),
    getValues(hookFormLabel.confirmloadingCharge),
    getValues(hookFormLabel.gstloadingCharge),
    getValues(hookFormLabel.confirmfreightCharge),
    getValues(hookFormLabel.gstfreightCharge),
    getValues(hookFormLabel.confirmotherCharge),
    getValues(hookFormLabel.gstotherCharge),
  ]);

  const retriggerCalculation = () => {
    const sumOfOtherChargesWithoutTax =
      Number(getValues(hookFormLabel.confirmprocessingCharge) ?? 0) +
      Number(getValues(hookFormLabel.confirmfreightCharge) ?? 0) +
      Number(getValues(hookFormLabel.confirmloadingCharge) ?? 0) +
      Number(getValues(hookFormLabel.confirmotherCharge) ?? 0);
    const totalInvBaseAmount = getTotalInvoiceAmt_baseValue(
      getValues(),
      shipmentDetails?.lineItemsDetails,
      sumOfOtherChargesWithoutTax,
    );
    const totalGSTInvAmount = getTotalInvoiceAmount_withGST(
      getValues(),
      shipmentDetails,
      sumOfOtherChargesWithoutTax,
      orderDetails,
    );
    let totalInvAmount =
      Number(totalInvBaseAmount ?? 0) + Number(totalGSTInvAmount ?? 0);
    totalInvAmount = decimalRoundOff(totalInvAmount);
    setValue(hookFormLabel.totalBaseInvoiceAmt, totalInvBaseAmount);
    setValue(hookFormLabel.totalGSTInvoiceAmount, totalGSTInvAmount);
    setValue(hookFormLabel.totalInvoiceAmt, totalInvAmount);
  };

  const setOtherServiceCharges = (invoiceOtherAmounts = {}) => {
    return registerServiceChargeValues(setValue, invoiceOtherAmounts);
  };

  const getCreditPeriod = useCallback(async () => {
    try {
      const res = await getCall_v2(
        `oms/credit/getCreditDueDayByShipmentNumber/${shipmentInfo.number}`,
      );
      setCreditPeriod(res.data);
    } catch (error) {
      console.warn("Unable to fetch the credit period", error);
    }
  }, [shipmentInfo]);

  useEffect(() => {
    setShipmentDetails(shipmentInfo);
    const { invoiceOtherAmounts } = shipmentInfo?.shipmentInvoiceDetails;
    const serviceChargeModel = getOtherServicesModel(invoiceOtherAmounts ?? {});

    setHoldPayout();
    setValue("invoiceDueDate", shipmentInfo?.creditInvoiceDueDate);

    setOtherServiceCharges(serviceChargeModel);

    setValue("shipmentStatus", shipmentInfo?.currentState?.state);

    if (isCreditOrder) {
      getCreditPeriod();
    }
    /* eslint-enable no-alert */
  }, [shipmentInfo, isCreditOrder]);

  const setHoldPayout = useCallback(() => {
    const { payoutHoldType, invoicePayoutHoldAmount, totalInvoicedAmount } =
      shipmentInfo?.shipmentInvoiceDetails;

    setValue("holdType", payoutHoldType || release);

    payoutHoldType === fullHold &&
      setValue("payoutHoldAmount", totalInvoicedAmount);
    payoutHoldType === partialHold &&
      setValue("payoutHoldAmount", invoicePayoutHoldAmount);
    /* eslint-enable no-alert */
  }, [shipmentInfo]);

  const setDefaultValue = () => {
    reset();
    setHoldPayout();
  };

  const orderOnTrack = useMemo(() => {
    let dateIsAfter;

    if (shipmentInfo?.currentState?.state === SHIPMENT_STATUS.CANCELLED) {
      return SHIPMENT_STATUS_CHIP.CANCELLED;
    }

    const actualDispatchDate = moment
      .utc(shipmentDetails?.actualDispatchDate)
      .format("L");

    switch (shipmentInfo?.currentState?.state) {
      case SHIPMENT_STATUS.READY_FOR_DISPATCH:
        dateIsAfter = moment(
          shipmentDetails?.plannedDispatchDate,
        ).isSameOrAfter(moment());
        break;
      case SHIPMENT_STATUS.DISPATCHED:
        dateIsAfter = moment(
          shipmentDetails?.plannedDispatchDate,
        ).isSameOrAfter(actualDispatchDate);
        break;
      case SHIPMENT_STATUS.DELIVERED:
        dateIsAfter = moment(
          shipmentDetails?.plannedDispatchDate,
        ).isSameOrAfter(actualDispatchDate);
        break;
      default:
        dateIsAfter = true;
        break;
    }
    if (dateIsAfter) {
      return SHIPMENT_STATUS_CHIP.ON_TIME;
    } else {
      return SHIPMENT_STATUS_CHIP.DELAYED;
    }
  }, [
    shipmentDetails?.actualDispatchDate,
    shipmentDetails?.plannedDispatchDate,
    shipmentInfo?.currentState?.state,
  ]);

  const handleRadioButton = ({ target: { value } }) => {
    setValue("holdType", value);

    const { invoicePayoutHoldAmount, totalInvoicedAmount } =
      shipmentDetails?.shipmentInvoiceDetails;

    if (value === fullHold) {
      setValue("payoutHoldAmount", totalInvoicedAmount);
    } else {
      value === partialHold && invoicePayoutHoldAmount
        ? setValue("payoutHoldAmount", invoicePayoutHoldAmount)
        : resetField("payoutHoldAmount");
    }
  };

  // const getInvoiceNo = (invoiceNumber) => {
  //   const invoiceNo = isDistributed
  //     ? invoiceNumber || getValues("invoiceNo")
  //     : invoiceNumber;
  //   return invoiceNo;
  // };

  const updateInvoiceDueDate = useCallback(
    (date) => {
      setValue(
        "invoiceDueDate",
        moment(date)
          .add(creditPeriod ?? 0, "days")
          .format("YYYY-MM-DD"),
      );
    },
    [creditPeriod, setValue],
  );

  // const isShipmentInvoiced = [
  //   shipmentStatus?.invoiced,
  //   shipmentStatus?.completed,
  // ].includes(shipmentDetails?.currentState?.state);

  const { DISPATCHED, DELIVERED, INVOICED, COMPLETED, READY_TO_SHIP, PLANNED } =
    SHIPMENT_STATUS;

  /**
   *@description Check whether tracking details is allowed to be updated
   */
  const shouldTrackingDetailsBeEnabled = [READY_TO_SHIP, DISPATCHED].includes(
    shipmentDetails?.currentState?.state,
  );

  if (!shipmentDetails) return <Loader />;

  const isPlantSupplyAndDispatched =
    isDirect &&
    [DISPATCHED, DELIVERED, INVOICED, COMPLETED].includes(
      shipmentDetails?.currentState?.state,
    );

  const isDispatchDateEnabled = [READY_TO_SHIP, DISPATCHED].includes(
    watch("shipmentStatus"),
  );

  const isInvoiceEnabled = [PLANNED, READY_TO_SHIP].includes(
    shipmentDetails?.currentState?.state,
  );

  // const handlePayoutConfirmation = async (isPayout) => {
  //   await handleShortClose(isPayout);
  // };

  const getMinDate = () => {
    const orderDate = new Date(getStartDate(orderDetails?.orderDate));
    const thresholdDate = new Date(orderDate);
    thresholdDate.setDate(
      orderDate.getDate() - orderDetails?.invoiceDateThreshold,
    );
    return thresholdDate;
  };

  return (
    <Box mb={4}>
      <CollapsibleInfo
        summaryStyles={{ alignItems: "center" }}
        defaultExpand={id === shipmentDetails?.id}
        summary={
          <ShipmentHeader
            invoicePostingStatus={getInvoicePostingStatus(shipmentDetails)}
            shipmentNumber={shipmentDetails?.number}
            plannedDispatchDate={shipmentDetails?.plannedDispatchDate}
            actualDispatchDate={shipmentDetails?.actualDispatchDate}
            sellerOrderNumber={shipmentDetails?.sellerOrderNumber}
            orderStatus={shipmentInfo?.currentState?.state}
            orderOnTrack={orderOnTrack}
            invoiceNumber={
              shipmentDetails?.shipmentInvoiceDetails?.invoiceDetails[0]
                ?.invoiceNumber ?? "-"
            }
            isCreditOrder={isCreditOrder}
            invoicePaymentStatus={shipmentDetails?.invoicePaymentStatus}
          />
        }
        details={
          <FormProvider {...methods}>
            <Box as="form" onSubmit={handleSubmit(onSubmitShipment)} p={2}>
              <ShipmentDeliveryData
                shipmentValue={
                  shipmentDetails?.shipmentInvoiceDetails?.shipmentValue
                }
                mode={shipmentDetails?.modeOfShipment}
                deliveryType={shipmentDetails?.deliveryType?.state}
              />
              <GridSkeleton
                containerStyle={{ marginBottom: 32 }}
                firstChild={
                  <Box>
                    {shipmentDetails?.currentState?.state ===
                    shipmentStatus.planned ? (
                      <>
                        <Typography
                          style={{ marginBottom: 8 }}
                          fontWeight={600}
                        >
                          Shipment status
                        </Typography>
                        <Controller
                          control={control}
                          name="shipmentStatus"
                          defaultValue={shipmentDetails.currentState?.state}
                          render={({ field: { value, onChange } }) => (
                            <DropdownV1
                              itemList={shipmentDetails.nextShipmentStates}
                              sx={{ width: "40%" }}
                              valueKey="state"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <CustomViewOnly
                        label="Shipment status"
                        value={shipmentDetails.currentState?.state}
                      />
                    )}
                  </Box>
                }
                secondChild={
                  <Controller
                    control={control}
                    name="actualDispatchDate"
                    defaultValue={shipmentDetails?.actualDispatchDate ?? null}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Typography mb={2} fontWeight={600}>
                          Dispatch date
                        </Typography>
                        <DatePickerV1
                          rest={{ flexDirection: "column" }}
                          minDate={getMinDate()}
                          value={value}
                          onChange={onChange}
                          disabled={!isDispatchDateEnabled}
                          dateStyle={{ maxWidth: 180 }}
                        />
                        <ErrorMsg msg={errors?.actualDispatchDate?.message} />
                      </>
                    )}
                  />
                }
                thirdChild={
                  <Controller
                    control={control}
                    name="actualDeliveryDate"
                    defaultValue={shipmentDetails?.actualDeliveryDate ?? null}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Typography mb={2} fontWeight={600}>
                          Delivery date
                        </Typography>
                        <DatePickerV1
                          rest={{ flexDirection: "column" }}
                          value={value}
                          minDate={getMinDate()}
                          dateStyle={{ maxWidth: 180 }}
                          onChange={onChange}
                        />
                        <ErrorMsg msg={errors.actualDeliveryDate?.message} />
                      </>
                    )}
                  />
                }
              />
              <GridSkeleton
                containerStyle={{ marginBottom: 32 }}
                firstChild={
                  <CustomViewOnly
                    label="Ship from"
                    value={getShipFromAddress(lineItems[0]?.sourceSeller)}
                  />
                }
              />
              <Box style={{ marginBottom: 32 }}>
                <ShipmentLineItem
                  retriggerCalculation={retriggerCalculation}
                  lineItemsDetails={shipmentDetails?.lineItemsDetails}
                  isPlantSupplyAndDispatched={isPlantSupplyAndDispatched}
                />
              </Box>
              <Divider style={{ marginBottom: 24 }} />
              <GridSkeleton
                containerStyle={{ marginBottom: 40 }}
                firstChild={
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box style={{ display: "flex", alignItems: "flex-end" }}>
                      <Controller
                        control={control}
                        name={`invoiceNo`}
                        defaultValue={
                          shipmentDetails?.shipmentInvoiceDetails
                            ?.invoiceDetails[0]?.invoiceNumber ?? ""
                        }
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Box
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography style={{ marginBottom: 8 }}>
                              Invoice No.
                            </Typography>
                            <CustomTextField
                              disablePaste={false}
                              value={value}
                              mask={true}
                              disabled={!isInvoiceEnabled}
                              onChange={onChange}
                              customPadding={2}
                              onBlur={onBlur}
                              highlightError={errors?.confirmInvoiceNo?.hasOwnProperty(
                                "message",
                              )}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`confirmInvoiceNo`}
                        defaultValue={
                          shipmentDetails?.shipmentInvoiceDetails
                            ?.invoiceDetails[0]?.invoiceNumber ?? ""
                        }
                        render={({ field: { value, onChange, onBlur } }) => (
                          <CustomTextField
                            disablePaste={false}
                            value={value}
                            onChange={onChange}
                            customPadding={2}
                            disabled={!isInvoiceEnabled}
                            onBlur={onBlur}
                            highlightError={errors?.confirmInvoiceNo?.hasOwnProperty(
                              "message",
                            )}
                          />
                        )}
                      />
                    </Box>
                    <ErrorMsg msg={errors?.confirmInvoiceNo?.message} />
                  </Box>
                }
                secondChild={
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Controller
                        control={control}
                        name="invoiceDate"
                        defaultValue={
                          shipmentDetails?.shipmentInvoiceDetails
                            ?.invoiceDetails[0]?.invoiceDate ?? null
                        }
                        render={({ field: { value, onChange } }) => (
                          <Box style={{ marginRight: 8 }}>
                            <Typography mb={2} fontWeight={600}>
                              Invoice date
                            </Typography>
                            <DatePickerV1
                              rest={{ flexDirection: "column" }}
                              disableFuture={
                                !(
                                  shipmentDetails.currentState?.state ===
                                  shipmentStatus.planned
                                )
                              }
                              minDate={getMinDate()}
                              value={value}
                              customPadding={10}
                              dateStyle={{ maxWidth: 180 }}
                              onChange={onChange}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="confirmInvoiceDate"
                        defaultValue={
                          shipmentDetails?.shipmentInvoiceDetails
                            ?.invoiceDetails[0]?.invoiceDate ?? null
                        }
                        render={({ field: { value, onChange } }) => {
                          const handleChange = (e) => {
                            updateInvoiceDueDate(e);
                            onChange(e);
                          };
                          return (
                            <DatePickerV1
                              rest={{ flexDirection: "column" }}
                              value={value}
                              disableFuture={
                                !(
                                  shipmentDetails.currentState?.state ===
                                  shipmentStatus.planned
                                )
                              }
                              minDate={getMinDate()}
                              dateStyle={{ maxWidth: 180 }}
                              customPadding={10}
                              onChange={(e) => handleChange(e)}
                            />
                          );
                        }}
                      />
                    </Box>
                    <ErrorMsg msg={errors?.confirmInvoiceDate?.message} />
                  </Box>
                }
                thirdChild={
                  isCreditOrder && (
                    <CustomViewOnly
                      label="Invoice due date"
                      value={dateConvertor(invoiceDueDate)}
                    />
                  )
                }
              />
              <GridSkeleton
                containerStyle={{ marginBottom: 40 }}
                firstChild={
                  <Controller
                    control={control}
                    name={hookFormLabel.totalBaseInvoiceAmt}
                    defaultValue={shipmentDetails?.shipmentInvoiceDetails?.invoicedBaseAmount.toString()}
                    render={({ field: { value } }) => (
                      <CustomViewOnly
                        label="Invoiced amount (₹-base value)"
                        value={moneyString(value)}
                      />
                    )}
                  />
                }
                secondChild={
                  <Controller
                    control={control}
                    name={hookFormLabel.totalGSTInvoiceAmount}
                    defaultValue={shipmentDetails?.shipmentInvoiceDetails?.invoicedTaxAmount.toString()}
                    render={({ field: { value } }) => (
                      <CustomViewOnly
                        label="Invoiced amount (₹-only GST)"
                        value={moneyString(value)}
                      />
                    )}
                  />
                }
                thirdChild={
                  <Controller
                    control={control}
                    name="totalInvoiceAmt"
                    defaultValue={shipmentDetails?.shipmentInvoiceDetails?.totalInvoicedAmount.toString()}
                    render={({ field: { value } }) => (
                      <CustomViewOnly
                        label="Total invoiced amount(₹)"
                        value={
                          value === "0"
                            ? moneyFormat(value)
                            : moneyString(value)
                        }
                      />
                    )}
                  />
                }
              />
              <GridSkeleton
                firstChild={
                  <Box style={{ display: "flex", alignItems: "flex-end" }}>
                    <Controller
                      control={control}
                      name={hookFormLabel.totalInvoiceOtherServicesAmt}
                      defaultValue={shipmentDetails?.shipmentInvoiceDetails?.invoiceOtherServicesSum.toString()}
                      render={({ field: { value } }) => (
                        <CustomViewOnly
                          label="Invoiced amount (₹-other services)"
                          value={moneyString(value)}
                        />
                      )}
                    />
                    <ButtonV1
                      title="Add/View"
                      variant="text"
                      color="secondary"
                      disabled={shipmentDetails.currentState?.state !== PLANNED}
                      onClick={toggleOtherServicesModal}
                    />
                  </Box>
                }
                secondChild={
                  isDirect && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <Typography style={{ marginBottom: 8 }}>
                        Seller Shipment no.
                      </Typography>
                      <Controller
                        control={control}
                        name={`sapShipmentNumber`}
                        defaultValue={shipmentDetails?.shipmentNumber}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            onPaste={(e) => e.preventDefault()}
                            onChange={onChange}
                            customPadding={10}
                          />
                        )}
                      />
                    </Box>
                  )
                }
              />
              <GridSkeleton
                containerStyle={{ marginBottom: 40 }}
                firstChild={
                  <Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography
                        fontWeight={600}
                        marginRight={8}
                        whiteSpace="nowrap"
                      >
                        Hold payout:
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          onChange={handleRadioButton}
                          value={holdType}
                          row
                        >
                          <FormControlLabel
                            value={fullHold}
                            control={<Radio size="small" />}
                            label="Full hold"
                            sx={{ fontSize: 14 }}
                          />
                          <FormControlLabel
                            value={partialHold}
                            control={<Radio size="small" />}
                            label="Partial hold"
                          />
                          <FormControlLabel
                            value={release}
                            control={<Radio size="small" />}
                            label="Release"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {holdType === fullHold && (
                      <HoldPayoutTextField
                        control={control}
                        holdType={holdType}
                      />
                    )}
                    {holdType === partialHold && (
                      <HoldPayoutTextField
                        control={control}
                        holdType={holdType}
                      />
                    )}
                    <ErrorMsg
                      msg={
                        holdType === partialHold
                          ? errors?.payoutHoldAmount?.message
                          : ""
                      }
                    />
                  </Box>
                }
                secondChild={
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box display={"flex"}>
                      <Typography style={{ marginBottom: 8 }} fontWeight={600}>
                        Invoice comment
                      </Typography>
                      {[fullHold, partialHold].includes(holdType) && (
                        <Typography style={{ color: theme.palette.error.dark }}>
                          *
                        </Typography>
                      )}
                    </Box>

                    <Controller
                      control={control}
                      name="invoiceComment"
                      defaultValue={
                        shipmentDetails?.shipmentInvoiceDetails
                          ?.invoiceComments ?? ""
                      }
                      render={({ field: { value, onChange } }) => (
                        <>
                          <TextareaAutosize
                            style={{
                              borderRadius: 8,
                              border: `1px solid ${theme.palette.grey[400]}`,
                              padding: 8,
                              fontFamily: "inherit",
                              resize: "none",
                            }}
                            value={value}
                            onChange={onChange}
                            minRows={2}
                            maxRows={2}
                          />
                          <ErrorMsg
                            msg={
                              holdType !== release
                                ? errors?.invoiceComment?.message
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Box>
                }
              />
              {isDirect ? (
                <>
                  {Object.keys(shipmentDetails).length && (
                    <TrackingShipment
                      trackingDetails={shipmentDetails?.freightInfo}
                      sapShipmentNumber={shipmentDetails?.shipmentNumber}
                      shipmentNumber={shipmentDetails?.number}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography fontWeight={"bold"} mb={4}>
                    Tracking details
                  </Typography>
                  <GridSkeleton
                    containerStyle={{ marginBottom: 40 }}
                    firstChild={
                      <>
                        <Typography style={{ marginBottom: 8 }}>
                          Driver No.
                        </Typography>
                        <Controller
                          control={control}
                          name={`driverNumber`}
                          defaultValue={
                            shipmentDetails?.vehicleDetails?.driverNumber
                          }
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              value={value}
                              size="small"
                              type="number"
                              onChange={onChange}
                              disabled={!shouldTrackingDetailsBeEnabled}
                            />
                          )}
                        />
                        <ErrorMsg msg={errors?.driverNumber?.message} />
                      </>
                    }
                    secondChild={
                      <>
                        <Typography style={{ marginBottom: 8 }}>
                          Truck No.
                        </Typography>
                        <Controller
                          control={control}
                          name={`truckNumber`}
                          defaultValue={
                            shipmentDetails?.vehicleDetails?.truckNumber
                          }
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              value={value}
                              size="small"
                              onChange={onChange}
                              disabled={!shouldTrackingDetailsBeEnabled}
                            />
                          )}
                        />
                        <ErrorMsg msg={errors?.truckNumber?.message} />
                      </>
                    }
                    thirdChild={
                      <>
                        <Typography style={{ marginBottom: 8 }}>
                          Transporter name
                        </Typography>
                        <Controller
                          control={control}
                          name={`driverName`}
                          defaultValue={
                            shipmentDetails?.vehicleDetails?.driverName
                          }
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              value={value}
                              size="small"
                              onChange={onChange}
                              disabled={!shouldTrackingDetailsBeEnabled}
                            />
                          )}
                        />
                        <ErrorMsg msg={errors?.driverName?.message} />
                      </>
                    }
                  />
                  <GridSkeleton
                    containerStyle={{ marginBottom: 40 }}
                    firstChild={
                      <>
                        <Typography style={{ marginBottom: 8 }}>
                          Lorry receipt no.
                        </Typography>
                        <Controller
                          control={control}
                          name={`truckReceiptNumber`}
                          defaultValue={
                            shipmentDetails?.vehicleDetails?.truckReceiptNumber
                          }
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              value={value}
                              size="small"
                              onChange={onChange}
                              disabled={!shouldTrackingDetailsBeEnabled}
                            />
                          )}
                        />
                        <ErrorMsg msg={errors?.truckReceiptNumber?.message} />
                      </>
                    }
                  />
                </>
              )}
              <ModalBox
                open={isOtherServicesModalOpen}
                onCloseModal={toggleOtherServicesModal}
                avoidBackDropClick={true}
                width={"45%"}
                paddingTop={0}
                hideCrossIcon={true}
                handleCloseIconClick={resetUnconfirmedCharges}
              >
                {/* {isZohoCustomer ? ( 
                  
                ) : (
                  <OtherServiceCharges
                    ref={otherChargeRef}
                    sellerOrderId={shipmentDetails?.sellerOrderId}
                    serviceCharges={getOtherServicesModel(
                      shipmentDetails?.shipmentInvoiceDetails
                        ?.invoiceOtherAmounts,
                    )}
                    submitCharges={() => {
                      calculateInvoiceAmt();
                      toggleOtherServicesModal();
                    }}
                    shipmentId={shipmentDetails?.id}
                    onCloseModal={toggleOtherServicesModal}
                  />
                )} */}
                <ZohoOtherServiceCharges
                  ref={otherChargeRef}
                  sellerOrderId={shipmentDetails?.sellerOrderId}
                  serviceCharges={getZohoOtherServicesModel(
                    zohoProcessingCharges,
                  )}
                  submitCharges={() => {
                    calculateInvoiceAmt();
                    toggleOtherServicesModal();
                  }}
                  shipmentId={shipmentDetails?.id}
                  onCloseModal={toggleOtherServicesModal}
                  processingCharges={shipmentDetails?.processing_charges}
                />
              </ModalBox>
              {/* File upload section */}
              <FileUpload
                fileList={
                  shipmentDetails?.shipmentInvoiceDetails?.invoiceDetails[0]
                    ?.files
                }
                shipmentId={shipmentDetails?.id}
                invoiceNumber={watch("invoiceNo")}
                ref={fileUploadRef}
              />
              <FloatingBar bottom={10} position="sticky">
                <Box display={"flex"} gap={6}>
                  <ButtonV1
                    // disabled={
                    //   orderDetails?.orderStatus === orderStatus?.delivered
                    // }
                    title="Update"
                    type="submit"
                    loader={loading}
                  />
                  <ButtonV1
                    variant="outlined"
                    title="Reset"
                    onClick={setDefaultValue}
                    disabled={!isDirty}
                  />
                </Box>
              </FloatingBar>
              {/* <DialogBox
                handleClose={() => setIsConfirmModalOpen(false)}
                open={isConfirmModalOpen}
                handleConfirm={handleConfirm}
                message={SHORT_CLOSE_MSG}
                title=""
                msgStyle={{
                  color: (theme) => theme.palette.grey["A800"],
                  fontWeight: "600",
                  fontSize: 16,
                }}
                buttonWrapperStyle={{ justifyContent: "center" }}
                okButtonVariant="contained"
                cancelButtonVariant="outlined"
                isCloseIcon={true}
              />

              <PayoutCreationModal
                isShortCloseLoader={isShortCloseLoader}
                isShortClosePayout={true}
                open={isPayoutModal}
                onCloseModal={() => setIsPayoutModal(false)}
                onConfirm={handlePayoutConfirmation}
              /> */}
            </Box>
          </FormProvider>
        }
      />
    </Box>
  );
};

ShipmentUpdate.propTypes = {
  shipmentInfo: PropTypes.object,
  id: PropTypes.number,
  getShipmentData: PropTypes.func,
  zohoProcessingCharges: PropTypes.array,
};

export default ShipmentUpdate;
