import { STATUS_CHIP } from "constants";
import {
  INVOICE_POSTING_LABEL,
  INVOICE_POSTING_STATUS,
} from "pages/OrderDetails/ShipmentsTab/constants";

export const getInvPostingData = (invoicePostingStatus) => {
  let result = { label: "", type: "" };

  switch (invoicePostingStatus) {
    case INVOICE_POSTING_STATUS.invCreated:
    case INVOICE_POSTING_STATUS.notSynced:
    case INVOICE_POSTING_STATUS.inProgress:
    case INVOICE_POSTING_STATUS.invProcessingPending:
    case INVOICE_POSTING_STATUS.invPosted: {
      result.type = STATUS_CHIP.warning;
      result.label = INVOICE_POSTING_LABEL.pending;
      break;
    }
    case INVOICE_POSTING_STATUS.invCreatedFail:
    case INVOICE_POSTING_STATUS.invPostingFail:
    case INVOICE_POSTING_STATUS.failed:
    case INVOICE_POSTING_STATUS.invRejection:
    case INVOICE_POSTING_STATUS.invProcessingFailed: {
      result.type = STATUS_CHIP.error;
      result.label = INVOICE_POSTING_LABEL.failed;
      break;
    }
    case INVOICE_POSTING_STATUS.invProcessed: {
      result.type = STATUS_CHIP.success;
      result.label = INVOICE_POSTING_LABEL.success;
      break;
    }
    default: {
      result = {};
    }
  }
  return result;
};
