export const shipmentUpdateHeaders = [
  "SKU",
  "Quantity planned",
  "Quantity invoiced",
  "Invoiced rate ₹price/unit",
  "Upfront discount ₹price/unit",
];

export const AMOUNT_MISMATCH_ERROR = "Inconsistent data";

export const GST_OPTIONS = [
  { label: "0%", value: "0" },
  { label: "5%", value: "5" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
];

export const hookFormLabel = {
  confirmprocessingCharge: "confirmprocessingCharge",
  gstprocessingCharge: "gstprocessingCharge",
  taxprocessingCharge: "taxprocessingCharge",

  confirmloadingCharge: "confirmloadingCharge",
  gstloadingCharge: "gstloadingCharge",
  taxloadingCharge: "taxloadingCharge",

  confirmfreightCharge: "confirmfreightCharge",
  gstfreightCharge: "gstfreightCharge",
  taxfreightCharge: "taxfreightCharge",

  confirmotherCharge: "confirmotherCharge",
  gstotherCharge: "gstotherCharge",
  taxotherCharge: "taxotherCharge",

  totalInvoiceOtherServicesAmt: "totalInvoiceOtherServicesAmt",
  totalGSTInvoiceAmount: "totalGSTInvoiceAmount",
  totalBaseInvoiceAmt: "totalBaseInvoiceAmt",
  totalInvoiceAmt: "totalInvoiceAmt",

  confirmInvoicedTcsAmount: "confirmInvoicedTcsAmount",
  invoicedTcsAmount: "invoicedTcsAmount",
};

export const fileUploadListData = {
  invoice: {
    label: "Invoices",
    fileType: "INVOICE",
    key: "invoice",
  },
  testCertificate: {
    label: "Test certificates",
    fileType: "TESTCERTIFICATE",
    key: "testCertificate",
  },
  weightmentSlips: {
    label: "Weightment slips",
    fileType: "WIEGHTSLIP",
    key: "weightmentSlips",
  },
  lorryReceipt: {
    label: "Lorry receipts",
    fileType: "LORRYRECEIPT",
    key: "lorryReceipt",
  },
  other: {
    label: "Others",
    fileType: "OTHERS",
    key: "other",
  },
};

export const success = "success";
export const error = "error";
export const fullHold = "FULL_HOLD";
export const partialHold = "PARTIAL_HOLD";
export const release = "RELEASE";

export const DO_lineItemHeaders = [
  "SKU",
  "Variant MM ID",
  "Total order qty",
  "Pending released qty",
  "Quantity",
  "Value",
];

export const defaultShipmentMode = "Road";
export const standardUnit = "MT";

export const orderStatus = {
  delivered: "Delivered",
  placed: "Placed",
  deliveryInProgress: "Delivery in progress",
  confirm: "Confirmed",
  cancelled: "Cancelled",
};

export const shipmentStatus = {
  invoiced: "Invoiced",
  planned: "Planned",
  completed: "Completed",
  delivered: "Delivered",
  cancelled: "Cancelled",
  dispatched: "Dispatched",
  readyToShip: "Ready to ship",
  readyForDispatch: "Ready for dispatch",
  readyForPickUp: "Ready for pickup",
};

export const FrieghtChargeWarning =
  "Freight value entered is more than combined freight amount at order";

export const freightCharge = "freightCharge";

export const INVOICED_VALIDATION_MSG =
  "Total invoiced, base and gst amount should be greater than 0";

export const priceWarningInvoiceRate =
  "Value is different than line item price";

export const priceInvalidWarningInvoiceRate = "Please enter valid unit rate";

export const INVOICED_FILE_VALIDATION_MSG = "Please upload invoice to proceed";

export const SHIPMENT_STATUS_CHIP = {
  ON_TIME: "onTime",
  DELAYED: "delayed",
  CANCELLED: "cancelled",
};

export const SHIPMENT_STATUS = {
  PLANNED: "Planned",
  READY_FOR_DISPATCH: "Ready for dispatch",
  DISPATCHED: "Dispatched",
  DELIVERED: "Delivered",
  INVOICED: "Invoiced",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  READY_TO_SHIP: "Ready to ship",
};

export const PAYMENT_TYPE = {
  cashNcarryAdvance: "Cash & Carry - Advance",
  cashNcarryPart: "Cash & Carry - Part",
  credit: "Credit",
};

export const CREDIT = "Credit";
export const DIRECT = "DIRECT";
export const PURCHASE_ORDER_ERR_MSG = "Please add purchase order ID";
export const SHORT_CLOSE_MSG =
  "90% ordered value is delivered. Do you want to short-close this order?";
export const FREIGHT_CHARGES = "FREIGHT_CHARGES";

export const lineItemHeaders = [
  "SKU",
  "Variant MM ID",
  "Total order qty",
  "Already planned qty",
  "Quantity",
  "Value",
];

export const INVOICE_POSTING_STATUS = {
  invCreated: "INV_CREATED",
  notSynced: "NOT_SYNCED",
  inProgress: "IN_PROGRESS",
  invProcessingPending: "INV_PROCESSING_PENDING",
  invPosted: "INV_POSTED",
  invCreatedFail: "INV_CREATE_FAIL",
  invPostingFail: "INV_POSTING_FAIL",
  failed: "FAILED",
  invRejection: "INV_REJECTED",
  invProcessingFailed: "INV_PROCESSING_FAILED",
  invProcessed: "INV_PROCESSED",
};

export const INVOICE_POSTING_LABEL = {
  pending: "Pending",
  failed: "Failed",
  success: "Success",
};

export const SHORT_CLOSE_TITLE_MSG =
  "Following items are fulfilled over 95% of the ordered quantity. Do you want to short close them?";
export const SHORT_CLOSE_DETAIL_MSG =
  "The selected line items will be short-closed at the Pending quantity";
