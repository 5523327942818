import { removeTimeOffset, getFixedValue } from "utils";
import {
  INVOICE_POSTING_LABEL,
  INVOICE_POSTING_STATUS,
  release,
} from "../constants";

/**
 * @description Format zohoService charges for payload
 */
const getFormattedZohoProcessingCharges = (data, oldShipmentVal) => {
  if (data?.length) {
    return data
      .map((item) => {
        return {
          description: item?.description ?? "",
          type: item?.type ?? "",
          taxPercentage: item?.percent ?? 0,
          igst: item?.igst ?? "",
          cgst: item?.cgst ?? "",
          creatorId: item?.creatorId ?? "",
          mmId: item?.mmId ?? "",
          chargeDescriptionType: item?.chargeDescriptionType ?? "",
          amount: item?.amount ?? 0,
        };
      })
      .filter((item) => item?.mmId);
  } else {
    return oldShipmentVal?.processing_charges;
  }
};

export const makeShipmentUpdationData = (
  updatedShipmentVal,
  oldShipmentVal,
  shipmentList,
  isCreditOrder,
  isDirect,
) => {
  try {
    const lineItemsDetails = [];
    let shipmentData = {};
    const selectedSellerShipments = shipmentList.find(
      (ele) => ele.sellerOrderId === oldShipmentVal.sellerOrderId,
    );
    const {
      actualDeliveryDate,
      actualDispatchDate,
      totalInvoiceAmt,
      totalBaseInvoiceAmt: invoicedBaseAmount,
      totalGSTInvoiceAmount: invoicedTaxAmount,
      totalInvoiceOtherServicesAmt,
      processingCharge,
      freightCharge,
      loadingCharge,
      otherCharge,
      invoiceNo,
      invoiceDate,
      invoiceComment,
      shipmentStatus,
      gstfreightCharge,
      gstloadingCharge,
      gstotherCharge,
      gstprocessingCharge,
      taxfreightCharge,
      taxloadingCharge,
      taxotherCharge,
      taxprocessingCharge,
      holdType,
      payoutHoldAmount,
      invoiceDueDate,
      zohoServiceCharges,
    } = updatedShipmentVal;

    let shipmentNumber;
    if (isDirect) {
      shipmentNumber = updatedShipmentVal?.sapShipmentNumber;
    }
    if (oldShipmentVal.shipmentInvoiceDetails) {
      shipmentData = {
        customerOrderId: oldShipmentVal.customerOrderId,
        sellerGstin: selectedSellerShipments?.sellerInfo?.gstin,
        invoiceDate: removeTimeOffset(invoiceDate) ?? "",
        actualDispatchDate: removeTimeOffset(actualDispatchDate) ?? "",
        actualDeliveryDate: removeTimeOffset(actualDeliveryDate) ?? "",
        currentState: { state: shipmentStatus },
        deliveryType: oldShipmentVal.deliveryType,
        id: oldShipmentVal.id,
        shipmentInvoiceDetails: {
          invoiceComments: invoiceComment,
          invoice: null,
          invoicedBaseAmount: Number(getFixedValue(invoicedBaseAmount) ?? 0),
          invoicedTaxAmount: Number(getFixedValue(invoicedTaxAmount) ?? 0),
          invoiceOtherServicesSum: Number(
            getFixedValue(totalInvoiceOtherServicesAmt) ?? 0,
          ),
          invoiceOtherAmounts: {
            freightCharge: freightCharge ?? "",
            loadingCharge: loadingCharge ?? "",
            otherCharge: otherCharge ?? "",
            processingCharge: processingCharge ?? "",
            freightChargeTax: taxfreightCharge ?? 0,
            loadingChargeTax: taxloadingCharge ?? 0,
            otherChargeTax: taxotherCharge ?? 0,
            processingChargeTax: taxprocessingCharge ?? 0,
            gstFreightCharge: gstfreightCharge ? Number(gstfreightCharge) : 0,
            gstLoadingCharge: gstloadingCharge ? Number(gstloadingCharge) : 0,
            gstOtherCharge: gstotherCharge ? Number(gstotherCharge) : 0,
            gstProcessingCharge: gstprocessingCharge
              ? Number(gstprocessingCharge)
              : 0,
          },
          invoicePayoutHoldAmount:
            holdType === release
              ? oldShipmentVal?.shipmentInvoiceDetails
                  ?.invoicePayoutHoldAmount ?? 0
              : +payoutHoldAmount,
          payoutHoldType: holdType,
          totalInvoicedAmount: totalInvoiceAmt,
        },
        shipmentNumber,
        modeOfShipment: oldShipmentVal.modeOfShipment,
        sellerOrderId: oldShipmentVal.sellerOrderId,
        ...(isCreditOrder && {
          invoiceDueDate: removeTimeOffset(invoiceDueDate) ?? "",
        }),
      };
    }

    if (oldShipmentVal.lineItemsDetails.length) {
      oldShipmentVal.lineItemsDetails.forEach((ele) => {
        let invoiceDeliveryId = null;
        let perUnitDiscountRateTax = 0;
        let perUnitFinalRateTax = 0;
        const perUnitFinalRate =
          updatedShipmentVal?.lineItemsDetails[ele.lineitemId]
            ?.confirmInvoicedRatePerUnit -
            updatedShipmentVal.lineItemsDetails[ele.lineitemId]
              .confirmDiscountPerUnit ?? 0;

        if (ele.invoiceDelivery) {
          const { id, invoiceInfo } = ele.invoiceDelivery;
          invoiceDeliveryId = id;

          if (invoiceInfo) {
            perUnitDiscountRateTax = invoiceInfo.perUnitDiscountRateTax ?? 0;
            perUnitFinalRateTax =
              invoiceInfo.perUnitRateTax - invoiceInfo.perUnitDiscountRateTax ??
              0;
          }
        }

        const temp = {
          gst: ele?.gst ?? "",
          taxType: ele?.taxType ?? "",
          invoiceDelivery: {
            id: invoiceDeliveryId ?? "",
            invoicedDeliveryDate: actualDeliveryDate,
            invoiceInfo: {
              measurementId: ele?.productInfo?.primaryQuantity?.split(" ")[1],
              perUnitDiscountRate:
                updatedShipmentVal.lineItemsDetails[ele.lineitemId]
                  .confirmDiscountPerUnit,
              perUnitDiscountRateTax,
              perUnitFinalRate,
              perUnitFinalRateTax,
              perUnitRate:
                updatedShipmentVal.lineItemsDetails[ele.lineitemId]
                  .confirmInvoicedRatePerUnit,
              perUnitRateTax: 0,
            },
            invoiceNumber: invoiceNo ? invoiceNo.trim() : "",
            quantity:
              updatedShipmentVal.lineItemsDetails[ele.lineitemId]
                .confirmInvoicedQty,
          },
          lineitemId: ele.lineitemId,
        };
        lineItemsDetails.push(temp);
      });
    }

    let vehicleDetails = {};

    if (!isDirect) {
      const { truckNumber, driverNumber, truckReceiptNumber, driverName } =
        updatedShipmentVal;
      vehicleDetails = {
        truckNumber: truckNumber,
        driverNumber: driverNumber,
        driverName: driverName,
        truckReceiptNumber: truckReceiptNumber,
      };
    }

    const response = {
      ...shipmentData,
      lineItemsDetails,
      vehicleDetails,
      processing_charges: getFormattedZohoProcessingCharges(
        zohoServiceCharges,
        oldShipmentVal,
      ),
    };
    return response;
  } catch (err) {
    console.error(`Error in updating data - ${err}`);
  }
};

export const getInvoicePostingStatus = (shipmentDetails) => {
  if (!shipmentDetails?.invoicePostingStatus) return "";
  const { invoicePostingStatus } = shipmentDetails;
  let label = "";

  switch (invoicePostingStatus) {
    case INVOICE_POSTING_STATUS.invCreated:
    case INVOICE_POSTING_STATUS.notSynced:
    case INVOICE_POSTING_STATUS.inProgress:
    case INVOICE_POSTING_STATUS.invProcessingPending:
    case INVOICE_POSTING_STATUS.invPosted: {
      label = INVOICE_POSTING_LABEL.pending;
      break;
    }
    case INVOICE_POSTING_STATUS.invCreatedFail:
    case INVOICE_POSTING_STATUS.invPostingFail:
    case INVOICE_POSTING_STATUS.failed:
    case INVOICE_POSTING_STATUS.invRejection:
    case INVOICE_POSTING_STATUS.invProcessingFailed: {
      label = INVOICE_POSTING_LABEL.failed;
      break;
    }
    case INVOICE_POSTING_STATUS.invProcessed: {
      label = INVOICE_POSTING_LABEL.success;
      break;
    }
    default: {
      label = "";
    }
  }
  return label;
};
