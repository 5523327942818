import { useCallback, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";

import { DatePickerV1, UploadCSV, ExportCta } from "components";

import ReviewContent from "./ReviewContent";
import { Filters } from "./components";

import { getCall_v2, postCall_v2 } from "services";
import { useToaster } from "hooks";
import { formatDate } from "utils";
import { error, success } from "constants";

const rowsPerPage = 50;

const BulkShipment = () => {
  const [filters, setFilters] = useState({});
  const [bulkShipmentData, setBulkShipmentData] = useState({});
  const [exportFrom, setExportFrom] = useState("");
  const [exportTill, setExportTill] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const csvUploadRef = useRef();

  const triggerToaster = useToaster();
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    fetchBulkShipments();
  }, [page, filters]);

  const fetchBulkShipments = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/bulk-shipment/fetch-uploaded-jopl-shipment`,
        {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            invoiceNumber: filters?.invoiceNumber || null,
            customerGstin: filters?.customerGSTIN || null,
            plantCode: filters?.plantCode || null,
            soNumber: filters?.soNumber || null,
            status: filters?.status || null,
            invoiceDateMin: filters?.invoiceDateMin || null,
            invoiceDateMax: filters?.invoiceDateMax || null,
            batchNumber: filters?.batchNumber || null,
            uploadDateMin: filters?.uploadDateMin || null,
            uploadDateMax: filters?.uploadDateMax || null,
          },
        },
      );

      if (data) {
        setBulkShipmentData(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSubmit = (filterData) => {
    setPage(0);
    setFilters(filterData);
  };

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  /**
   * @description callback fn on file upload
   */
  const fileUploadCallback = async (file) => {
    setIsFileUploading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await postCall_v2(
          `/joms/api/external/v1/bulk/sap/upload`,
          formData,
        );
        if (res) {
          fetchBulkShipments();
        }
        if (res?.data) {
          triggerToaster(res?.data, success);
        }
      } catch (err) {
        triggerToaster(
          err?.data?.errorMessage || `Error occurred, please try again`,
          error,
        );
      } finally {
        setIsFileUploading(false);
        csvUploadRef?.current?.resetFile();
      }
    }
  };

  const onExport = async () => {
    const { data } = await getCall_v2(
      `/joms/api/external/v1/bulk-shipment/export-upload-details?from=${exportFrom}&to=${exportTill}`,
    );
    if (data?.isSuccess) {
      triggerToaster("Details will be sent in mail, please check", success);
    } else {
      triggerToaster(data?.error?.message ?? "Please try again", error);
    }
  };

  const convertDateFormat = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Box mb={8}>
        <UploadCSV
          headerText={"Bulk upload"}
          fileCallBackFn={fileUploadCallback}
          allowedMaxSize={5}
          ref={csvUploadRef}
          isLoading={isFileUploading}
          fileType={"xlsx"}
        />
      </Box>

      <Box
        mb={4}
        display={"flex"}
        justifyContent={"end"}
        gap={4}
        alignItems={"center"}
      >
        <DatePickerV1
          value={exportFrom}
          disableFuture={true}
          inputProps={{
            placeholder: "Export from",
          }}
          onChange={(ev) => {
            setExportFrom(convertDateFormat(formatDate(ev)));
          }}
        />
        <DatePickerV1
          disableFuture={true}
          value={exportTill}
          inputProps={{
            placeholder: "Export till",
          }}
          onChange={(ev) => {
            setExportTill(convertDateFormat(formatDate(ev)));
          }}
        />
        <ExportCta onExport={onExport} />
      </Box>

      <Box
        style={{
          padding: 10,
          borderRadius: 12,
          border: `1px solid ${theme.palette.grey["400"]}`,
        }}
      >
        <Box mb={4}>
          <Filters
            onFilterSubmit={handleFilterSubmit}
            totalRecords={bulkShipmentData?.totalCount}
          />
        </Box>

        <ReviewContent
          bulkShipmentData={bulkShipmentData}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

export default BulkShipment;
