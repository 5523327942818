const plantSupplyTabs = {
  OLD_BULK_SHIPMENT: "Old shipment upload",
  BULK_SHIPMENT: "Shipment upload",
  PLANT_SUPPLY_TAB: "Plant Supply",
  DO_SHORTCLOSE: "DO short closure approval",
  SHIPMENT_CREATION_PLANT_SUPPLY: "Shipment creation - Plant supply",
  SHIPMENT_CREATION_JODL: "Shipment creation - JODL",
};
const SO_DETAIL_COLUMN_HEADER = "SO details";
const SO_TYPE = ["PRODUCTION_SO", "DISPATCH_SO"];
const PLANT_SUPPLY_HEADER = [
  "Buyer’s Order ID",
  "Customer Name",
  "SKU Name",
  "Quantity Ordered",
  "Quantity Shipped",
  "Price/Unit",
  SO_DETAIL_COLUMN_HEADER,
];
const DO_SHORTCLOSE_HEADER = [
  "DO ID",
  "Sales order ID",
  "SKU name",
  "Released SKU quantity",
  "Shipment Quantity",
  "Residual quantity",
  "Residual value",
  "Released value",
  "Source Seller Name",
  "Seller GSTIN",
  "DO request created at",
  "DO request created by",
  "DO request approved at",
  "DO request approved by",
  "Remark",
  "Action",
];
const DO_STATUS = {
  approved: "Approved",
  rejected: "Rejected",
  requested: "Requested",
};

const filterQueries = {
  buyerOrderId: "Buyer's order ID",
  soDetails: "SO details",
};

const SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS = [
  "Order Number",
  "Buyer Name (ERP)",
  "Buyer Name (SAP)",
  "Invoice Number",
  "Invoice Value",
  "SKU (ERP)",
  "SKU (SAP)",
  "Quantity Invoiced",
  "Invoice posting status",
  "Actions",
];

const SHIPMENT_CREATION_JODL_HEADERS = [
  "Order Number",
  "Seller Order Number",
  "Buyer Name (ERP)",
  "Mode of Shipment",
  "Invoice Number",
  "Invoice Value",
  "SKU (ERP)",
  "Quantity Invoiced",
  "Other Charges",
  "Invoice posting status",
  "Actions",
];

const BULK_SHIPMENT_CATEGORY = {
  bulkUpload: "Bulk_Upload",
  jodl: "JODL",
  plantSupply: "JOPL",
};

const SHIPMENT_STATUS = {
  pending: "PENDING",
  approved: "APPROVED",
  rejected: "REJECTED",
  inProgress: "IN-PROCESS",
  failed: "FAILED",
  created: "CREATED",
};

const STATUS_STATES = {
  requested: "Requested",
  approved: "Approved",
  rejected: "Rejected",
};

const QUICK_FILTERS = {
  Pending: "PENDING",
  All: null,
  "Have error": "HAVE_ERROR",
  Created: "CREATED",
  // Approved: "APPROVED",
  Rejected: "REJECTED",
};

const OTHER_CHARGES_HEADERS = [
  "Service Sku",
  "Base Amount",
  "Tax Amount",
  "Total",
];

export {
  plantSupplyTabs,
  SO_DETAIL_COLUMN_HEADER,
  DO_SHORTCLOSE_HEADER,
  PLANT_SUPPLY_HEADER,
  SO_TYPE,
  filterQueries,
  DO_STATUS,
  QUICK_FILTERS,
  SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS,
  SHIPMENT_CREATION_JODL_HEADERS,
  BULK_SHIPMENT_CATEGORY,
  SHIPMENT_STATUS,
  OTHER_CHARGES_HEADERS,
  STATUS_STATES,
};
